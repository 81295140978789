<template>
  <div class="animated">
    <b-card>
      <div slot="header">
        <i class="icon-speech"></i> Triggers
      </div>
      <b-button variant="success" v-on:click="openCreateModal" :disabled="data.length >= limit">
        <font-awesome-icon icon="plus" style="margin-right:5px" />Create new trigger
      </b-button>
      <br> <br>
      <p v-show="data.length > limit"> Triggers Limit exceeded {{limit}} </p>
      <hr />
      <div v-if="loading">
        <div class="sk-circle-fade float-left">
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
        </div>
        <h2 class="float-left ml-4">Loading triggers...</h2>
      </div>
      <div v-else>
        <v-client-table
          :columns="columns"
          :data="data"
          :options="options"
          :theme="theme"
          id="dataTable"
          style="width: 100%; margin: none"
        >
          <p
            slot="response"
            class="d-none d-md-inline-block"
            slot-scope="props"
            style="max-width: 780px !important"
            block
          >
            {{props.row.response}}
            <b-badge
              v-if="Object.keys(JSON.parse(props.row.embed)).length > 1"
              class="ml-2"
              variant="primary"
              pill
            >embed</b-badge>
          </p>
          <p slot="type" slot-scope="props" block>{{matchtypes[props.row.match_type - 1]}}</p>
          <b-button
            slot="actions"
            slot-scope="props"
            block
            variant="outline-primary"
            v-on:click="openEditModal(props.row)"
          >Edit</b-button>
        </v-client-table>
      </div>
    </b-card>
    <b-modal size="lg" v-model="largeModal" @ok="largeModal = false" :no-enforce-focus="true">
      <template slot="modal-title">
        <h4 class="smalltitle">
          <i class="icon-speech" style="margin-right:10px"></i>Edit Trigger
        </h4>
      </template>
      <b-form-group>
        <h4 class="smalltitle">Trigger</h4>
        <b-input-group>
          <b-form-input
            type="text"
            pattern="[A-z0-9-_ ]{1,50}"
            class="col-12"
            :disabled="true"
            v-model="trigger_name"
          ></b-form-input>
        </b-input-group>
        <hr />
        <h4 class="smalltitle">Response</h4>
        <b-input-group>
          <b-form-textarea
            rows="6"
            type="text"
            placeholder="Enter some trigger content"
            class="col-12"
            v-model="trigger_content"
          ></b-form-textarea>
        </b-input-group>
        <hr />
        <b-form-group>
          <h4 class="smalltitle">Match Type</h4>
          <b-form-select id="server_channel_select" v-model="trigger_type" value="Please select">
            <option v-for="t in match_mapping" :key="t.id" :value="t.id">{{t.name}}</option>
          </b-form-select>
          <b-form-select
            id="server_channel_select"
            v-model="trigger_channel"
            value="Please select"
            v-if="trigger_type === 3"
            style="margin-top:10px;"
          >
            <option v-for="c in channels" :key="c.id" :value="c.id">#{{c.name}}</option>
          </b-form-select>
          <hr />
          <EmbedBuilder ref="embed_builder_edit"></EmbedBuilder>
        </b-form-group>
      </b-form-group>
      <div slot="modal-footer" class="w-100">
        <b-container fluid class="text-light text-center" style="padding-right:0px;">
          <b-row class="mr-auto">
            <b-col cols="auto" class="mr-auto p-2">
              <b-button
                v-b-tooltip.hover
                :title="trigger_id != undefined ? 'Delete trigger' : 'Refresh the page if you wish to delete this trigger'"
                variant="ghost-danger"
                :disabled="trigger_id == undefined"
                v-on:click="deleteTrigger"
              >
                <i class="icon-trash"></i>
              </b-button>
            </b-col>
            <b-col cols="auto" class="p-2">
              <b-button variant="ghost-secondary" v-on:click="largeModal = false">Cancel</b-button>
            </b-col>
            <b-col cols="auto" class="p-2">
              <b-button variant="primary" v-on:click="editTrigger">Save</b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>

    <b-modal size="lg" v-model="createModal" @ok="xd = false" :no-enforce-focus="true">
      <template slot="modal-title">
        <h4 class="smalltitle">
          <i class="icon-speech" style="margin-right:10px"></i>Create Trigger
        </h4>
      </template>
      <b-form-group>
        <h4 class="smalltitle">Trigger</h4>
        <b-input-group>
          <b-form-input
            type="text"
            pattern="[A-z0-9-_ ]{1,50}"
            class="col-12"
            v-model="trigger_name"
          ></b-form-input>
        </b-input-group>
        <hr />
        <h4 class="smalltitle">Response</h4>
        <b-input-group>
          <b-form-textarea
            rows="6"
            type="text"
            pattern="[a-z0-9-_]{1,5000}"
            placeholder="Enter some trigger content"
            class="col-12"
            v-model="trigger_content"
          ></b-form-textarea>
        </b-input-group>
        <hr />
        <b-form-group>
          <h4 class="smalltitle">Match Type</h4>
          <b-form-select id="server_channel_select" v-model="trigger_type" value="Please select">
            <option v-for="t in match_mapping" :key="t.id" :value="t.id">{{t.name}}</option>
          </b-form-select>
          <b-form-select
            id="server_channel_select"
            v-model="trigger_channel"
            value="Please select"
            v-if="trigger_type === 3"
            style="margin-top:10px;"
          >
            <option v-for="c in channels" :key="c.id" :value="c.id">#{{c.name}}</option>
          </b-form-select>

          <hr />
          <EmbedBuilder ref="embed_builder_create"></EmbedBuilder>
        </b-form-group>
      </b-form-group>
      <div slot="modal-footer" class="w-100">
        <b-container fluid class="text-light text-center" style="padding-right:0px;">
          <b-row class="mr-auto">
            <b-col cols="auto" class="mr-auto p-2"></b-col>
            <b-col cols="auto" class="p-2">
              <b-button variant="ghost-secondary" v-on:click="createModal = false">Cancel</b-button>
            </b-col>
            <b-col cols="auto" class="p-2">
              <b-button variant="success" v-on:click="createTrigger">Create</b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { ClientTable, Event } from "vue-tables-2";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import Multiselect from "vue-multiselect";
import EmbedBuilder from "@/views/oc/EmbedBuilder";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn"
};
miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const toast_options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};
Vue.use(VueNotifications, toast_options);
Vue.use(ClientTable);

export default {
  name: "DataTable",
  components: {
    ClientTable,
    Event,
    cSwitch,
    Multiselect,
    EmbedBuilder
  },
  notifications: {
    added_trigger: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Trigger created"
    },
    edited_trigger: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Trigger edited"
    },
    deleted_trigger: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Trigger deleted"
    },
    fill_in_fields: {
      type: VueNotifications.types.warn,
      title: "Uh oh",
      message: "Please fill in all fields"
    },
    create_error: {
      type: VueNotifications.types.error,
      title: "Uh oh",
      message: "Something went wrong!"
    }
  },
  data: function() {
    return {
      columns: ["trigger", "response", "type", "actions"],
      data: [],
      limit: 0,
      channels: [],
      ignored_channels: [],
      matchtypes: [
        "Normal",
        "Strict",
        "Channel specific",
        "Exact",
        "Startswith",
        "Endswith"
      ],
      options: {
        filterByColumn: true,
        columnsDisplay: {
          response: "not_mobile",
          type: "not_mobile"
        },
        texts: {
          filter: "Filter",
          filterBy: "Filter by {column}"
        },
        sortable: ["trigger", "response"],
        filterable: ["trigger", "response"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        },
        childRow: false,
        highlightMatches: true
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      match_mapping: [
        { id: 1, name: "Normal" },
        { id: 2, name: "Strict" },
        { id: 3, name: "Channel specific" },
        { id: 4, name: "Exact" },
        { id: 5, name: "Startswith" },
        { id: 6, name: "Endswith" }
      ],
      largeModal: false,
      createModal: false,
      trigger_name: "",
      trigger_content: "",
      trigger_type: 1,
      trigger_channel: null,
      trigger_id: null,
      loading: true
    };
  },
  methods: {
    openEditModal(row) {
      this.trigger_name = row.trigger.trim();
      this.trigger_content = row.response.trim();
      this.trigger_type = row.match_type;
      this.trigger_channel = row.specific_channel || null;
      this.largeModal = true;
      var embed_var = JSON.parse(row.embed);
      setTimeout(() => {
        this.$refs.embed_builder_edit.apply_json(embed_var);
        this.$refs.embed_builder_edit.show_embed =
          Object.keys(embed_var).length > 1;
        this.trigger_id = row.id;
        this.$forceUpdate();
      }, 20);
    },
    openCreateModal() {
      this.trigger_name = "";
      this.trigger_content = "";
      this.trigger_type = 1;
      this.trigger_channel = null;
      this.createModal = true;
      setTimeout(() => {
        this.$refs.embed_builder_create.resetEmbed();
        this.$refs.embed_builder_create.show_embed = false;
      }, 20);
    },
    editTrigger() {
      if (
        !this.trigger_name.trim() ||
        (!this.trigger_content.trim() &&
          Object.keys(this.$refs.embed_builder_edit.full_json).length < 2)
      ) {
        return this.fill_in_fields();
      }
      if (this.trigger_type === 3 && !this.trigger_channel) {
        return this.fill_in_fields();
      }
      this.data.forEach(e => {
        if (e.trigger === this.trigger_name) {
          e.response = this.trigger_content;
          e.match_type = this.trigger_type;
          e.specific_channel = this.trigger_channel || null;
          e.embed = JSON.stringify(this.$refs.embed_builder_edit.full_json);
        }
      });
      this.patchTrigger();
      this.edited_trigger();
    },
    createTrigger() {
      if (
        !this.trigger_name.trim() ||
        (!this.trigger_content.trim() &&
          Object.keys(this.$refs.embed_builder_create.full_json).length < 2)
      ) {
        return this.fill_in_fields();
      }
      if (this.trigger_name.trim().length < 3){
      return this.create_error({message: "Trigger name cannot be less than 3 characters"})
      }
      this.putTrigger().then(res => {
        var found = false;
        this.data.forEach(e => {
          if (e.trigger === this.trigger_name) {
            e.response = this.trigger_content;
            e.match_type = this.trigger_type;
            e.specific_channel = this.trigger_channel || null;
            e.embed = JSON.stringify(this.$refs.embed_builder_create.full_json);
            found = true;
          }
        });
        if (!found  && res) {
          this.data.push({
            id: res,
            trigger: this.trigger_name,
            response: this.trigger_content,
            match_type: this.trigger_type,
            embed: JSON.stringify(this.$refs.embed_builder_create.full_json)
          });
        }
      });
    },
    patchTrigger() {
      this.axios
        .patch("/api/v1/servers/" + this.$route.params.guild_id + "/triggers", {
          trigger: this.trigger_name,
          response: this.trigger_content,
          match_type: this.trigger_type,
          specific_channel: this.trigger_channel,
          embed: this.$refs.embed_builder_edit.full_json
        })
        .then(this.closeModals());
    },
    deleteTrigger() {
      this.axios
        .delete(
          "/api/v1/servers/" +
            this.$route.params.guild_id +
            "/triggers/" +
            this.trigger_id, {
              data: {
                name: this.trigger_name
              }
            }
        )
        .then(this.closeModals());
      for (let [i, e] of this.data.entries()) {
        if (e.trigger === this.trigger_name) {
          this.data.splice(i, 1);
        }
      }
      this.deleted_trigger();
    },
    putTrigger() {
      this.closeModals();
      return this.axios
        .put("/api/v1/servers/" + this.$route.params.guild_id + "/triggers", {
          trigger: this.trigger_name,
          response: this.trigger_content,
          match_type: this.trigger_type,
          specific_channel: this.trigger_channel,
          embed: this.$refs.embed_builder_create.full_json
        })
        .then(r => {
        if (!r.data.success){
        this.create_error({message: r.data.msg});
        return;
        }
        setTimeout(
             () =>
         this.axios
       .get("/api/v1/servers/" + this.$route.params.guild_id + "/triggers")
       .then(r => {
         this.data = r.data.triggers;
         this.channels = r.data.channels;

         this.ignored_channels =
           this.channels.filter(x => r.data.ignored_channels.includes(x.id)) ||
           [];
       }),2000),
          this.added_trigger();
          return r.data.id;
        });
    },
    closeModals() {
      this.largeModal = false;
      this.createModal = false;
    }
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/triggers")
      .then(r => {
        this.data = r.data.triggers;
        this.channels = r.data.channels;

        this.ignored_channels =
          this.channels.filter(x => r.data.ignored_channels.includes(x.id)) ||
          [];
      });
  },
  created: function() {
    this.$Progress.start();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/triggers")
      .then(r => {
        this.data = r.data.triggers;
        this.limit = r.data.limit;
        this.channels = r.data.channels;

        this.ignored_channels =
          this.channels.filter(x => r.data.ignored_channels.includes(x.id)) ||
          [];
        this.loading = false;
        this.$Progress.finish();
      });
  }
};
</script>

<style lang="scss">
#dataTable {
  width: 95%;
  margin: 0 auto;

  .VuePagination {
    text-align: center;
    justify-content: center;
  }

  .td {
    word-wrap: !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .vue-title {
    text-align: center;
    margin-bottom: 10px;
  }

  .VueTables__search-field {
    display: flex;
  }
  .VueTables__search-field input {
    margin-left: 0.25rem;
  }

  .VueTables__limit-field {
    display: flex;
  }

  .VueTables__limit-field select {
    margin-left: 0.25rem !important;
  }

  .VueTables__table th {
    text-align: center;
  }

  .VueTables__child-row-toggler {
    width: 16px;
    height: 16px;
    line-height: 16px;
    display: block;
    margin: auto;
    text-align: center;
  }

  .VueTables__child-row-toggler--closed::before {
    content: "+";
  }

  .VueTables__child-row-toggler--open::before {
    content: "-";
  }
}
</style>
